<template>
    <v-dialog
        v-model="dialog"
        width="300px"
    >
        <v-card class="opti-card">
            <v-toolbar
                flat
                dense
            >
                <v-toolbar-title class="pa-0 ma-0">
                    {{ $t(titleText) }}
                </v-toolbar-title>
                <v-spacer />
                <v-btn
                    icon
                    @click="dialog = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                {{ contentText }}
                <br>
                {{ contentSubText }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    class="ma-0"
                    color="success"
                    :disabled="loading"
                    @click="clickConfirm"
                >
                    {{ $t(confirmButtonText) }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        titleText: {
            type: String,
            default: null
        },
        contentText: {
            type: String,
            default: null
        },
        contentSubText: {
            type: String,
            default: null
        },
        confirmButtonText: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            dialog: false
        }
    },
    computed: {
    },
    created() {
        this.dialog = true
    },
    methods: {
        clickConfirm() {
            this.$emit('close')
            this.dialog = false
        }
    }
}
</script>
