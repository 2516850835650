import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"300px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"opti-card"},[_c(VToolbar,{attrs:{"flat":"","dense":""}},[_c(VToolbarTitle,{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(_vm.$t(_vm.titleText))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_vm._v(" "+_vm._s(_vm.contentText)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.contentSubText)+" ")]),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"ma-0",attrs:{"color":"success","disabled":_vm.loading},on:{"click":_vm.clickConfirm}},[_vm._v(" "+_vm._s(_vm.$t(_vm.confirmButtonText))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }